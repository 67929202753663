import React from 'react';

import './NotificationBanner.css'

const NotificationBanner = () => (
    <div className="banner">
        <p>Hey! I'm building this site in <em>real time.</em> Keep an eye on it as it transforms! ✨</p>
    </div>
)

export default NotificationBanner