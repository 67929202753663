import React from 'react';
import { Link } from 'react-router-dom';

import "../../css/fonts.css";
import "./Header.css";

const Header = ({ logo, aboutLink }) => { 
  return (
  <div className="Header">
    <div className="Header__logo">
      <Link to={'/'}>
        The Back To Work Report
      </Link>
    </div>
    <div className="Header__nav">
      <Link to={aboutLink}>
              ?
      </Link>
    </div>
  </div>
)}

export default Header
