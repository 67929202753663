import React from 'react';

import PostItem from '../PostIndex/PostItem';

import './PostItem.css';

const PostIndex = ({ posts }) => {
    return (
        <section className="Post-index-wrapper">
            <h5>Recent Posts</h5>
            <div className="Post-index">
                {posts.map((post) => {
                    return <PostItem post={post} key={post.id}/>
                })}
            </div>
        </section>
    );
};

export default PostIndex;
