import React from 'react';
import { RichText } from 'prismic-reactjs';

const ImageSlice = ({ slice }) => (
    <div className="ImageSlice">
        <img src={slice.primary.image.url} alt={slice.primary.image.alt}/>
        {slice.primary.image_caption ? RichText.render(slice.primary.image_caption) : ''}
    </div>
);

export default ImageSlice;