import React, { useEffect, useState } from 'react';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout/Layout';
import NotFound from '../pages/NotFound';

import { client } from '../prismic-config';

import SliceZone from '../components/Slices/SliceZone';
import "../css/fonts.css";
import "../css/post.css";

const Post = ({ match }) => {
  const [prismicDoc, setPrismicDoc] = useState(null);
  const [notFound, toggleNotFound] = useState(false);
  
  const uid = match.params.uid;

  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const doc = await client.getByUID('post', uid);

        if (doc) {
          setPrismicDoc(doc);
        } else {
          console.warn('Blog post document was not found. Make sure it exists in your Prismic repository');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    }

    fetchPrismicData();
  });

  if (prismicDoc) {
    const title =
      prismicDoc.data.title.length !== 0 ?
      RichText.asText(prismicDoc.data.title) :
      'Untitled';

    const featureImage = 
      prismicDoc.data.header_image.url ?
      prismicDoc.data.header_image.url :
      '';

    const imageAlt = 
      prismicDoc.data.header_image.alt ?
      prismicDoc.data.header_image.alt :
      '';

    const author = 
      prismicDoc.data.author_id[0].text ?
      prismicDoc.data.author_id[0].text :
      '';

    const category = 
      prismicDoc.data.categories[0].category;

    return (
      <Layout title={title}>
        <section className="Post-wrapper">
          <p className="Post-category">{category.slug === 'stay-home-stay-safe' ? 'Stay Home, Stay Safe' : 
              category.slug === 'thinking-thoughts' ? 'Thinking Thoughts' : 
              category.slug === 'travel--trips' ? 'Travel & Trips' : 
              category.slug === 'coding' ? 'Coding' : ''}</p>
          <figure className="Post-feature-image">
            <img src={featureImage} alt={imageAlt}/>
          </figure>
          <div className="Post-feature-content">
            <h1>{title}</h1>
            <p>by {author}</p>
          </div>
          <SliceZone sliceZone={prismicDoc.data.body}/>
        </section>
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  } else {
    return null;
  }
};

export default Post
