import React from 'react';
import { RichText } from 'prismic-reactjs';

import '../../css/slices.css'

const BodyText = ({ slice }) => (
    <div className="BodyText">
        {RichText.render(slice.primary.text)}
    </div>
)

export default BodyText;