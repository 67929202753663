import React from 'react';
import ImageSlice from './ImageSlice';
import BodyText from './BodyText';
import EmbedSlice from './EmbedSlice';
import SectionHeaderSlice from './SectionHeaderSlice';

const SliceZone = ({ sliceZone }) => (
    sliceZone.map((slice, index) => {
        console.log(slice)
        switch(slice.slice_type) {
            case ('image'):
                return <ImageSlice slice={slice} key={index}/>
            case ('text'):
                return <BodyText slice={slice} key={index}/>
            case ('embed'):
                return <EmbedSlice slice={slice} key={index}/>
            case ('section_header'):
                return <SectionHeaderSlice slice={slice} key={index}/>
            default:
                return null
        }
    })
);

export default SliceZone;