import React, { Fragment } from 'react';
import About from './pages/About';
import Home from './pages/Home';
import Post from './templates/post';
import NotFoundPage from './pages/NotFound';
import {
    //BrowserRouter,
    Route,
    Switch,
    HashRouter
    // Redirect
  } from 'react-router-dom';

const App = () => {
    return (
        <Fragment>
            <HashRouter>
                <Switch>
                    {/* <Redirect exact from='/' to='/' /> */}
                    <Route exact path='/' component={Home} />
                    <Route exact path='/about' component={About} />
                    <Route exact path='/post/:uid' component={Post} />
                    <Route component={NotFoundPage} />
                </Switch>
            </HashRouter>
        </Fragment>
    )
}

export default App