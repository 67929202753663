import React from 'react';

const Footer = () => (
    <footer style={{ fontSize: `14px`, padding: `2em 0`, color: `rgba(72, 72, 72, 0.7)` }}>
    © The Back To Work Report {new Date().getFullYear()} | Built by <a style={{ color: `rgba(72, 72, 72, 0.7)` }} href="https://www.halimatubakarr.com">Halimatu Bakarr</a> with
    in React with Prismic & 🎧
    </footer> 
);

export default Footer;
