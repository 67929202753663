import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';

import './PostItem.css';

const PostItem = ({ post }) => {
    const title = 
        RichText.asText(post.data.title) ? 
        RichText.asText(post.data.title) : 'Untitled';
    const description = 
        post.data.description[0].text ?
        post.data.description[0].text : '';
    const img_src = post.data.header_image.url;
    const img_alt = post.data.header_image.alt;
    
    return (
        <div className="Post-item">
            <Link to={`post/${post.uid}`}>
                <img src={img_src} alt={img_alt}/>
            </Link>
            <h2>{title}</h2>
            <p>{description}</p>
            <Link to={`post/${post.uid}`}>
                Read More
            </Link>
        </div>
    );
};

export default PostItem;