import React from "react"

import AboutImg from "../images/about-me.jpg"

import "../css/fonts.css"
import "../css/about.css"
import Layout from "../components/Layout/Layout"

const About = () => (
  <Layout title="About">
      <div className="About-image"
           style={{ background: `url(${AboutImg})`, backgroundRepeat: `no-repeat`, backgroundSize: `auto`, backgroundPosition: `center`, width: `100%`, height: `300px` }}></div>
      <section className="About-content">
        <h3>About The Blog</h3>
        <p>
          The Back To Work Report is a blog mainly about travel, and feeling inspired. I also write about the process of building this site from scratch to improve my web development skills. Thank you for reading!
        </p>

        <div className="About-me">
          <h3>About Me</h3>
          <p>
            Writing about myself is such a chore but here goes: My name is Halimatu I am a web developer with a strong passion for travel. I'm an introvert but I thrive when I'm with the right group of people. You can usually find me a local, trendy coffee shop wherever I am. And if I'm not there, I'm under the covers watching whatever's good on Netflix. Check out my <a href="https://www.halimatubakarr.com" style={{ color: `rgba(26, 26, 26, 1.00)`}}>other website</a> if you want to see some of the things I made!
          </p>
        </div>
      </section>
  </Layout>
)

export default About
