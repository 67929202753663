import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import Prismic from 'prismic-javascript';
import { client } from '../prismic-config';
import PostIndex from '../components/PostIndex/PostIndex';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Hero/Hero';
import NotFound from './NotFound';

const Home = () => {

    const [allData, setPrismicData] = useState({ homeDoc: null, blogPosts: null, allPosts: null });
    const [notFound, toggleNotFound] = useState(false);

    ReactGA.initialize('UA-173358642-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const homeDoc = await client.query(
                    Prismic.Predicates.at('document.type', 'landing_page')
                );
                const blogPosts = await client.query(
                    Prismic.Predicates.at('document.type', 'post'),
                    { orderings: '[my.post.date desc]', pageSize: 6, page: 1 }
                );
                const allPosts = await client.query(
                    Prismic.Predicates.at('document.type', 'post'),
                    { orderings: '', pageSize: 25}
                );

                if (blogPosts) {
                    setPrismicData({ blogPosts: blogPosts.results, homeDoc: homeDoc.results, allPosts: allPosts.results });
                } else {
                    console.warn('Not found');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, []);

    if (allData.blogPosts) {
        const homeDoc = allData.homeDoc;
        const blogPosts = allData.blogPosts;

        return (
            <Layout title='Home'>
                <Hero home={homeDoc}/>
                <PostIndex posts={blogPosts}/>
            </Layout>
        );
    } else if(notFound) {
        return <NotFound/>
    }else {
        return null
    };
}

export default Home;