import React from 'react';
import { Link } from 'react-router-dom';

import './Hero.css'

const Hero = ({ home }) => {
    const description = home[0].data.description[0].text;
    
    return (
        <section 
        className="Hero"
        style={{ 
            background: `url(${home[0].data.home_header.url})`,
            backgroundSize: `cover`,
            backgroundPosition: `center`
        }}>
        <div className="call-out-wrapper">
            <div className="call-out">
                <p>{description}</p>
                <Link to={'/about'}>Learn More</Link>
            </div>
        </div>
    </section>
    )
}

export default Hero;