import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { Helmet } from "react-helmet";


import logo from '../../images/btwr_header_logo_type.svg';
import './Layout.css';



const Layout = ({ children, title }) => (
    <>
      <NotificationBanner/>
      <Helmet>
        <title>BTWR | {title}</title>
      </Helmet>
      <Header logo={logo} aboutLink={`/about`}/>
      <div>
        <main>
          {children}
        </main>
      </div>
      <section className="blm-banner">
        <p className="blm-link">
            <a href="https://blacklivesmatters.carrd.co/">Black Lives Matter. [Click Me]</a>
        </p>
      </section>
      <hr/>
      <Footer/>
    </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
