import React from "react"

const NotFoundPage = () => (
  <div>
    <h1>NOT FOUND</h1>
    <p>Not sure how you got here but this page doesn't exist <span role="img" aria-label="shrug emoji">🤷🏾‍</span></p>
  </div>
)

export default NotFoundPage
