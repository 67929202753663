import React from 'react';

import '../../css/slices.css'

const SectionHeaderSlice = ({ slice }) => (
    <div className="HeaderText">
        {slice.primary.section_header[0].text}
    </div>
)

export default SectionHeaderSlice;